import Decimal from '@st/decimal'
import type { CasinoLastBet } from '@st/casino/components/LastBets/types'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'

export interface UseFreeSpinBetReturn {
  freeSpinResultClass: ComputedRef<'positive' | 'negative' | 'neutral'>
  isFreespin: ComputedRef<boolean>
  freeSpinFiatSignBetResult: ComputedRef<string>
  freeSpinResult: ComputedRef<'loss' | 'win' | 'refund'>
  freeSpinBetsNumber: ComputedRef<number>
  freeSpinFormatedBetsNumber: ComputedRef<string | number>
}

export function useFreeSpinBet(bet: Ref<CasinoLastBet>) {
  const isFreespin = computed(() => bet.value.isFreespin)
  const freeSpinWinAmount = computed(
    () => bet.value.winsAmountInGameCurrency ?? '0',
  )
  const freeSpinBetAmount = computed(
    () => bet.value.betsAmountInGameCurrency ?? '0',
  )
  const freeSpinResult = computed(() => {
    if (freeSpinWinAmount.value === '0') return 'loss'
    if (freeSpinWinAmount.value !== freeSpinBetAmount.value) return 'win'

    return 'refund'
  })
  const freeSpinResultClass = computed(
    () =>
      ({
        loss: 'negative',
        win: 'positive',
        refund: 'neutral',
      })[freeSpinResult.value],
  )

  const { currencies } = storeToRefs(useCurrenciesStore())
  const gameCurrencyCode = computed(
    () => currencies.value[bet.value.gameCurrencyId]?.code ?? 'USD',
  )
  const { format: formatSignFiat } = useCurrencyFormatter({
    currency: gameCurrencyCode,
    signDisplay: 'exceptZero',
  })
  const freeSpinFiatSignBetResult = computed(
    () =>
      ({
        win: formatSignFiat(freeSpinWinAmount.value),
        loss: formatSignFiat(
          new Decimal(freeSpinWinAmount.value)
            .minus(freeSpinBetAmount.value)
            .toString(),
        ),
        refund: formatSignFiat(freeSpinBetAmount.value),
      })[freeSpinResult.value],
  )
  const freeSpinBetsNumber = computed(() => bet.value?.betsNumber ?? 1)
  const freeSpinFormatedBetsNumber = computed(() => {
    if (freeSpinResult.value === 'loss') return `-${freeSpinBetsNumber.value}`
    return freeSpinBetsNumber.value
  })

  return {
    isFreespin,
    freeSpinResultClass,
    freeSpinFiatSignBetResult,
    freeSpinResult,
    freeSpinBetsNumber,
    freeSpinFormatedBetsNumber,
  }
}
